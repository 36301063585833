<template>
  <l-input
      v-model="model"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      class="masked-input"
      type="number"
      :label="label"
      :suffix="suffix"
      :mask="inputMask"
      :append-inner-icon="appendInnerIcon"
      :prepend-inner-icon="prependInnerIcon"
      :min="min"
      :max="max"
      :step="step"
      :initial-value="initialValue"
      :required="required"
      :text-center="textCenter"
      :data-cy="dataCy"
  >
    <template v-if="slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </l-input>
</template>

<script setup lang="ts">
import {BaseInputProps} from "~/components/LComponents/Props/BaseInputProps.client";
import {NumberInputProps} from "~/components/LComponents/Props/NumberInputProps";
import {mdiPlus, mdiMinus} from '@mdi/js';
import {StateProps} from "~/components/LComponents/Props/StateProps";
import {TextInputProps} from "~/components/LComponents/Props/TextInputProps";
import {ThemeProps} from "~/components/LComponents/Props/ThemeProps";
import {TestIdProps} from "~/components/LComponents/Props/TestIdProps";
import {HintProps} from "~/components/LComponents/Props/HintProps";
import {IconsProps} from "~/components/LComponents/Props/IconsProps";
import mask from "maska/src/mask";

const MAX_NUMBER_OF_DIGITS = 10;

const props = defineProps({
  modelValue: {
    type: [Number, null,],
    required: true,
  },
  ...BaseInputProps,
  ...NumberInputProps,
  ...HintProps,
  ...IconsProps,
  ...StateProps,
  ...TextInputProps,
  ...ThemeProps,
  ...TestIdProps,
  suffix: {
    type: [String, null,],
    default: 'zł',
  },
  textCenter: {
    default: true,
    type: Boolean,
  },
  appendInnerIcon: {
    type: [String, null,],
    default: mdiPlus,
  },
  prependInnerIcon: {
    type: [String, null,],
    default: mdiMinus,
  },
  delayMasked: {
    type: Boolean,
    default: false,
  },
  withMasking: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['update:modelValue',]);
const slots = useSlots();

const model = computed<number | null>({
  get: () => {
    if (props.delayMasked && !isFocused.value) {
      masked.value = true;
    }

    return inputValue.value ?? props.modelValue;
  },
  set: (value) => {
    if (value !== props.modelValue) {
      emit('update:modelValue', value);
    }
  },
});

const masked: Ref<boolean> = ref(true);
const isFocused: Ref<boolean> = ref(false);
const inputValue: Ref<number | null> = ref(null);

const inputMask = computed(() => {
  if (!masked.value || !props.withMasking) {
    return Array(MAX_NUMBER_OF_DIGITS).fill('#').join('');
  }

  let lastMask = '###';
  let maskArray = ['"###"',];
  for (let index = 3; index < MAX_NUMBER_OF_DIGITS; index++) {
    if (index % 3 === 0) {
      lastMask = ` ${lastMask}`;
    }

    lastMask = `#${lastMask}`;
    maskArray.push(`"${lastMask}"`);
  }

  return `[${maskArray.join(',')}]`;
});

const onFocus = () => {
  isFocused.value = true;
  if (props.delayMasked) {
    masked.value = false;
  }
};

const onBlur = () => {
  isFocused.value = false;
};

const onInput = (event: Event) => {
  if (!event?.target || !(event instanceof InputEvent)) {
    return;
  } else if (props.delayMasked) {
    inputValue.value = event?.target?.value;
    return;
  }

  const currentEventTarget: HTMLInputElement = event.target;
  const modelLength = mask(String(currentEventTarget.value), inputMask.value).length;
  const positionFromEnd = currentEventTarget.value.length - currentEventTarget.selectionStart;

  nextTick(() => {
    const newValue = currentEventTarget.value;
    const newLength = newValue.length;
    if (newLength - currentEventTarget.selectionStart !== positionFromEnd) {
      const newPosition = Math.max(newLength - positionFromEnd, 0);
      currentEventTarget.selectionStart = newPosition;
      currentEventTarget.selectionEnd = newPosition;
    }
  });
};

watch(() => props.modelValue,(newValue) => {
  if (props.delayMasked) {
    inputValue.value = newValue;
  }
});
</script>

<style lang="scss">
.masked-input {
  position: relative;

  .v-field__append-inner > *:not(.v-icon) {
    font-weight: 600;
    left: calc(50% + var(--inputWidth) / 2 + 4px);
    position: absolute;
  }
}
</style>
